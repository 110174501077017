import API from '@/api';
import type {
	OfferwallPendingEvents,
} from '@/types';

//TODO remove it in the summer, when all old offerwalls pending for all users not will be actual
export const getOfferWallPending =
	async (): Promise<OfferwallPendingEvents> => {
		const { data } = await API.get<OfferwallPendingEvents>(
			'/api/offerwall-events/pending'
		);

		return data;
	};