import { FeatureFlags } from '@/enums';
import { USER_ROUTE_NAME } from '@/constants/routes';
import {
	checkSurveyClickIdHook,
	checkEmailSubscriptionHook,
	checkFeatureFlagsHook,
	checkHistoryTypeHook,
	checkInitializedClaim,
	showAdjoeOfferwall,
  checkHomePageAvailability,
} from '@/router/hooks';

export const userRoutes = [
  {
		path: '/',
		name: USER_ROUTE_NAME.HOME,
		component: () => import('@/views/home/HomeView.vue'),
		beforeEnter: [checkHomePageAvailability, checkSurveyClickIdHook],
	},
	{
		path: '/surveys',
		name: USER_ROUTE_NAME.SURVEYS,
		component: () => import('@/views/surveys/SurveysView.vue'),
		beforeEnter: checkSurveyClickIdHook,
		meta: {
			isPsPage: true,
		},
	},
	{
		path: '/profile',
		name: USER_ROUTE_NAME.PROFILE,
		component: () => import('@/views/profile/ProfileView.vue'),
	},
	{
		path: '/settings',
		name: USER_ROUTE_NAME.SETTINGS,
		component: () => import('@/views/settings/SettingsView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/reward-history/:type?',
		name: USER_ROUTE_NAME.REWARD_HISTORY,
		component: () => import('@/views/history/HistoryView.vue'),
		beforeEnter: checkHistoryTypeHook, 
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/leaderboard',
		name: USER_ROUTE_NAME.LEADERBOARD,
		component: () => import('@/views/leaderboard/LeaderboardView.vue'),
	},
	{
		path: '/confirm-claim',
		name: USER_ROUTE_NAME.CONFIRM_CLAIM,
		component: () => import('@/views/confirm-claim/ConfirmClaimView.vue'),
		beforeEnter: checkInitializedClaim,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/prize-draw',
		name: USER_ROUTE_NAME.PRIZE_DRAW,
		component: () => import('@/views/prize-draw/PrizeDrawView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/referrals',
		name: USER_ROUTE_NAME.REFERRALS,
		component: () => import('@/views/referrals/ReferralsView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/recent-activity',
		name: USER_ROUTE_NAME.RECENT_ACTIVITY,
		component: () => import('@/views/IntegrationSinglePageView.vue'),
		meta: {
			hideMobileBalance: true,
			isPsPage: true,
		},
	},
	{
		path: '/support',
		name: USER_ROUTE_NAME.SUPPORT,
		component: () => import('@/views/IntegrationSinglePageView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/email-subscriptions',
		name: USER_ROUTE_NAME.EMAIL_SUBSCRIPTIONS,
		beforeEnter: checkEmailSubscriptionHook,
		component: () =>
			import('@/views/email-subscriptions/EmailSubscriptionsView.vue'),
		meta: {
			ignoreAuth: true,
			hideMobileBalance: true,
		},
	},
	{
		path: '/achievements',
		name: USER_ROUTE_NAME.ACHIEVEMENTS,
		component: () => import('@/views/achievements/AchievementsView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/bonus-day',
		name: USER_ROUTE_NAME.BONUS_DAY,
		component: () => import('@/views/bonus-day/BonusDayView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/offers',
		name: USER_ROUTE_NAME.OFFERS,
		component: () => import('@/views/offers/OffersView.vue'),
		meta: {
			feature: FeatureFlags.PS_OFFERS,
			isPsPage: true,
		},
		beforeEnter: checkFeatureFlagsHook,
	},
	{
		path: '/recent-activity-offers',
		name: USER_ROUTE_NAME.RECENT_ACTIVITY_OFFERS,
		component: () => import('@/views/IntegrationSinglePageView.vue'),
		meta: {
			hideMobileBalance: true,
			isPsPage: true,
		},
	},
	{
		path: '/games',
		name: USER_ROUTE_NAME.GAMES,
		component: () => import('@/views/games/GamesView.vue'),
		beforeEnter: showAdjoeOfferwall,
	},
];
