import { defineStore } from 'pinia';
import { getWelcomeBonuses, selectWelcomeBonus } from '@/api';
import type { BonusItem, ErrorResponse, MessageResponse } from '@/types';
import { useUserStore } from '@/stores/user';
import { sendErrorInfo } from '@/utils/errorCatching';
import { getErrorResponse } from '@/utils/helpers';
import type { AxiosError } from 'axios';

interface BonusState {
	data: BonusItem[];
	message: string;
	loading: boolean;
	initLoading: boolean;
	error: ErrorResponse | null;
}

export const useBonusStore = defineStore('bonus', {
	state: (): BonusState => ({
		data: [],
		message: '',
		loading: false,
		initLoading: false,
		error: null,
	}),
	getters: {
		presetBonus(state): BonusItem | null {
			return state.data?.[0] || null;
		},
		minClaimBalance(state): number {
			return state.data?.[0]?.min_claim_balance || 0;
		},
		coinValue(state): number {
			return state.data?.[0]?.coin_value || 0;
		}
	},
	actions: {
		async fetchBonusList(): Promise<void> {
			try {
				this.initLoading = true;
				const res = await getWelcomeBonuses();
				this.data = res?.bonuses || [];
			} catch (error) {
				this.error = getErrorResponse(error);
				sendErrorInfo(error);
			} finally {
				this.initLoading = false;
			}
		},

		async selectBonus(): Promise<MessageResponse> {
			const userStore = useUserStore();
			try {
				this.loading = true;
				const res = await selectWelcomeBonus();
				return res;
			} catch (error: unknown) {
				sendErrorInfo(error);
				this.error = getErrorResponse(error);
				const axiosError = error as AxiosError;
				return {
					message: this.error.message,
					status: axiosError.response?.status,
				};
			} finally {
				await userStore.fetchUserData();
				this.loading = false;
			}
		},
	},
});
