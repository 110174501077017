<script lang="ts" setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import {
	OKAY,
	WELL_DONE,
	YOU_EARNED,
	WE_CREDITED_YOU_CURRENCY,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { OffersReward } from '@/types';
import { transformCurrency } from '@/utils/helpers';
import currencyEarned from '@/assets/icons/currency-earned.svg';
import { useUserStore } from '@/stores/user';

defineProps<{
	options: OffersReward | undefined;
}>();

const emits = defineEmits<{
	'close-modal': [];
}>();

const { currency } = storeToRefs(useUserStore());
const isDisabled = ref(false);

const onClose = () => {
	isDisabled.value = true;
	emits('close-modal');
};
</script>

<template>
	<div class="one-click-survey">
		<ModalCommonContent>
			<template #header>{{ $t(WELL_DONE) }}</template>
			<template #icon>
				<img
					:src="currencyEarned"
					alt="earned currency logo"
					class="fade-in logo"
				/>
			</template>
			<template #title>
				<PrimeText weight="600" size="lg">
					{{ $t(YOU_EARNED) }}
					{{ transformCurrency(+options?.currency_earned!, currency, 2) }}
				</PrimeText>
			</template>
			<template #message>
				{{
					$t(WE_CREDITED_YOU_CURRENCY, {
						value: transformCurrency(+options?.currency_earned!, currency, 2),
						name: options?.offer_name,
					})
				}}
			</template>
			<template #actions>
				<PrimeButton
					full-width
					:label="$t(OKAY)"
					:disabled="isDisabled"
					rounded="rounded"
					@click="onClose"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.one-click-survey {
	:deep(.modal-common-content) {
		.content-title {
			margin-bottom: 0.75rem;
		}

		.content-icon {
			width: 12.5rem;
			height: 12.5rem;
			margin-bottom: 0.75rem;
		}

		.content-description {
			margin-bottom: 1.375rem;
			padding-bottom: 1.375rem;
		}

		.content-actions {
			margin-top: 1.375rem;
		}
	}
}
</style>
