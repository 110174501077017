<script setup lang="ts">
import {
	ASK_PERMISSIONS_MODAL_HEADER,
	ASK_PERMISSIONS_MODAL_IMPORTANT,
	ASK_PERMISSIONS_MODAL_MESSAGE,
	ASK_PERMISSIONS_MODAL_TITLE,
	SELECT_CHOICE,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PiHandCoinsDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ImportantNote from '@/components/ImportantNote.vue';

const emits = defineEmits(['close-modal']);

const handleChoice = () => {
	emits('close-modal');
};
</script>

<template>
	<div class="ask-permissions-modal">
		<ModalCommonContent>
			<template #header>{{ $t(ASK_PERMISSIONS_MODAL_HEADER) }}</template>
			<template #icon>
				<PiHandCoinsDuotoneThin color="base-primary" size="100%" />
			</template>
			<template #title>
				<PrimeText tag="div" weight="500">
					{{ $t(ASK_PERMISSIONS_MODAL_TITLE) }}
				</PrimeText>
			</template>
			<template #message>
				<div>
					<PrimeText tag="div" size="sm" color="grey-700">
						{{ $t(ASK_PERMISSIONS_MODAL_MESSAGE) }}
					</PrimeText>

					<ImportantNote :text="$t(ASK_PERMISSIONS_MODAL_IMPORTANT)" />
				</div>
			</template>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(SELECT_CHOICE)"
					@click="handleChoice"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.ask-permissions-modal {
	:deep(.modal-common-content) {
		& .content-title,
		& .content-message {
			max-width: initial;
		}
	}
}
</style>
