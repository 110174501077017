<script lang="ts" setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { SELECT_COUNTRY_LANGUAGE_MODAL } from '@/constants/modals';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { PiGlobe } from '@primeinsightsgroupllc/prime-icons';
import { AUTH_ROUTE_NAME } from '@/constants/routes';
import { useRippleHoverEffect } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { createNewModal } = useModalStorage();
const authStore = useAuthStore();
const { localizationText } = storeToRefs(authStore);
const { createRipple } = useRippleHoverEffect();
const buttonRef = ref<HTMLElement | null>(null);

const openLanguageModal = (event: MouseEvent) => {
	createRipple(buttonRef.value, event);
	createNewModal(SELECT_COUNTRY_LANGUAGE_MODAL, {
		scopedToRoute: AUTH_ROUTE_NAME.APP_LOGIN,
	});
};
</script>

<template>
	<button ref="buttonRef" class="language-switcher" data-test-id="change-lang-btn" @click="openLanguageModal">
		<PiGlobe class="icon" color="inherit" />
		{{ localizationText }}
	</button>
</template>

<style lang="scss" scoped>
.language-switcher {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.2;
	padding: 0.3125rem 0.625rem;
	color: var(--text-default);
	border: 1px solid var(--grey-200);
	background: var(--grey-200);
	transition: background 0.2s;
	border-radius: 3.125rem;

	&:hover,
	&:focus {
		background: transparent;
	}

	.icon {
		margin-right: 0.5rem;
	}
}
</style>
