import { Echo } from '@/utils/echo';
import { ref, watch, onUnmounted, computed } from 'vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { UPDATED_BALANCE } from '@/locales/constants';
import {
	ACHIEVEMENT_UNLOCKED_MODAL,
	OFFERS_REWARD_NOTIFICATION_MODAL,
	USER_LEVEL_MODAL,
	USER_STREAK_MODAL,
	OFFERS_WELL_DONE_MODAL,
	CASHBACK_RECEIVED_NOTIFICATION_MODAL,
	CASHBACK_ANNOUNCEMENT_MODAL,
} from '@/constants/modals';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { useMessagesStore } from '@/stores/messages';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import type {
	AchievementUnlockedLevel,
	UserLevel,
	UserStreak,
	OfferwallRewardEvent,
	UserBalanceUpdateInfo,
	OffersTransactionDelayed,
	CashbackRewardEvent,
	FeatureFlagsData,
} from '@/types';
import { MessageChannels, TrackingEvents } from '@/enums';
import { useAppStore } from '@/stores/app';
import { useAppReviewPrompt } from '@/utils/composables/useInAppReview';

export const useAppPusherEventListener = (): {
	initPusherConnection: () => Promise<void>;
	removePusherConnection: () => void;
} => {
	const { readNotification } = useMessagesStore();
	const { createNewModal } = useModalStorage();
	const { t } = useI18n();
	const echo = Echo.getInstance();
	const channelName = ref('');
	const userStore = useUserStore();
	const { userId } = storeToRefs(userStore);
	const { userAuthToken } = storeToRefs(useAuthStore());
	const appStore = useAppStore();
	const userAndToken = computed(() => userId.value + userAuthToken.value);
	const { showInAppReviewPrompt } = useAppReviewPrompt();

	const removePusherConnection = () => {
		echo.removeConnections();
	};

	const initPusherConnection = async () => {
		if (!userId.value || !userAuthToken.value) return;

		channelName.value = `user-notifications-${userId.value}`;
		if (echo.isCurrentConnection(channelName.value, userAuthToken.value)) {
			return;
		}

		echo.removeConnections();

		try {
			await echo.createConnection(userAuthToken.value);
		} catch (error) {
			console.error('Error while creating WebSocket connection:', error);
		}

		window.Echo.private(channelName.value)
			.listen('.feature-flags-update', (event: FeatureFlagsData) => {
				userStore.updateFeatures(event);
			})
			.listen(
				// Balance update
				'.balance-updated',
				async (event: UserBalanceUpdateInfo) => {
					userStore.setCollectedCoins(event.new_balance);
					userStore.setCollectedCoinsBalance(event.currency_balance);
					if (!event.is_silent) {
						notify({ body: t(UPDATED_BALANCE) });
					}

					if (!event.is_silent && event.transaction_type === 1) {
						await showInAppReviewPrompt();
					}
				}
			)
			.listen(
				// Level update
				'.user-survey-taken',
				(event: UserLevel) => {
					userStore.setLevel(event);
				}
			)
			.listen(
				// Level update
				'.user-level-up',
				(event: UserLevel) => {
					userStore.setLevel(event);
					createNewModal(USER_LEVEL_MODAL, { ...event, shared: true });
				}
			)
			.listen(
				// Streak update
				'.user-streak-updated',
				(event: UserStreak) => {
					const userStreakLength = userStore.streaks;
					userStore.setStreak(event);
					if (userStreakLength === 0 && event.length === 1) {
						createNewModal(USER_STREAK_MODAL, {
							...event,
							shared: true,
						});
					}
				}
			)
			.listen(
				// Achievement level is unlocked/ready to claim
				'.achievement-level-ready-to-claim',
				(event: AchievementUnlockedLevel) => {
					createNewModal(ACHIEVEMENT_UNLOCKED_MODAL, {
						...event,
						shared: true,
					});
				}
			)
			.listen(
				'.offerwall_transaction_delayed',
				(event: OffersTransactionDelayed) => {
					createNewModal(OFFERS_WELL_DONE_MODAL, {
						...event,
						shared: true,
						onClose: () => readNotification(event.notification_id),
					});
					userStore.fetchOfferwallPending();
				}
			)
			.listen(
				'.offerwall_transaction_completed',
				async (event: OfferwallRewardEvent) => {
					userStore.setCollectedCoins(event.new_balance);
					userStore.setCollectedCoinsBalance(event.currency_balance);
					userStore.fetchOfferwallPending();
					createNewModal(OFFERS_REWARD_NOTIFICATION_MODAL, {
						...event,
						id: event.notification_id,
						channel: MessageChannels.NOTIFICATION,
						shared: true,
						onClose: () => readNotification(event.notification_id),
					});

					await showInAppReviewPrompt();
				}
			)
			.listen('.claim_cashback_received', (event: CashbackRewardEvent) => {
				userStore.setCollectedCoins(event.new_balance);
				userStore.setCollectedCoinsBalance(event.currency_balance);
				createNewModal(CASHBACK_RECEIVED_NOTIFICATION_MODAL, {
					...event,
					channel: MessageChannels.NOTIFICATION,
					shared: true,
					onClose: () => readNotification(event.notification_id),
				});
			})
			.listen(
				'.onetime-introduce-to-cashbacks',
				(event: CashbackRewardEvent) => {
					createNewModal(CASHBACK_ANNOUNCEMENT_MODAL, {
						...event,
						id: event.notification_id,
						channel: MessageChannels.NOTIFICATION,
						expiry: event?.expiry,
						percentage: event?.percentage,
						onClose: () => readNotification(event.notification_id),
					});
				}
			)
			.listen(
				'.in_market_offerwall_transaction_completed',
				(event: OfferwallRewardEvent) => {
					userStore.setCollectedCoins(event.new_balance);
					userStore.setCollectedCoinsBalance(event.currency_balance);
					createNewModal(OFFERS_REWARD_NOTIFICATION_MODAL, {
						...event,
						id: event.notification_id,
						shared: true,
						onClose: () => readNotification(event.notification_id),
					});
				}
			)
			.listen(
				'.gtm_track_event',
				async ({
					       event_name,
					       event_name_adjust,
					       params,
				       }: {
					event_name: TrackingEvents;
					event_name_adjust: string;
					params: Record<string, any>;
				}) => {
					appStore.addLog(
						`WS GTM TRACK EVENT:::${event_name}:::${event_name_adjust}:::${JSON.stringify(params)}`
					);
					await appStore.trackEvent(event_name, params, event_name_adjust);
				}
			);
	};

	watch(
		userAndToken,
		async () => {
			await initPusherConnection();
		},
		{ immediate: true }
	);

	onUnmounted(() => {
		removePusherConnection();
	});

	return {
		initPusherConnection,
		removePusherConnection,
	};
};
