import { useScrollLock } from '@vueuse/core';
import { watch } from 'vue';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { PostMessages } from '@/enums';
import { usePostMessageHandler } from '@/utils/composables/usePostMessageHandler';
import { storeToRefs } from 'pinia';

export const useBlockScroll = () => {
	const isLocked = useScrollLock(document.body);
	const isLockedHtml = useScrollLock(document.documentElement);
	const { activeModal } = storeToRefs(useModalStorage());

	watch(
		activeModal,
		(newValue) => {
			isLocked.value = !!Object.keys(newValue).length;
			isLockedHtml.value = !!Object.keys(newValue).length;
		},
		{ immediate: true, deep: true }
	);

	usePostMessageHandler((event: MessageEvent) => {
		if (event.data.message === PostMessages.SCROLL_BLOCK) {
			isLocked.value = event.data.value;
			isLockedHtml.value = event.data.value;
		}
	});
};
