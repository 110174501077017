<script setup lang="ts">
import {
	NO_CONNECTION_MESSAGE,
	NO_CONNECTION_TITLE,
	RELOAD,
} from '@/locales/constants';
import NoConnectionIcon from '@/components/NoConnectionIcon.vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useNetwork, useScrollLock } from '@vueuse/core';
import { onBeforeUnmount, onMounted } from 'vue';

const isLocked = useScrollLock(document.body);
const { isOnline } = useNetwork();

onMounted(() => {
	isLocked.value = true;
});

onBeforeUnmount(() => {
	isLocked.value = false;
});

const handleReloadPage = async () => {
	const status = useNetwork();
	if (status.isOnline) {
		if (isOnline.value) {
			document.location.reload();
		}
	}
};
</script>

<template>
	<div class="no-connection">
		<div class="no-connection-wrapper">
			<NoConnectionIcon />
			<div class="no-connection-content">
				<PrimeText tag="div" size="xl" weight="500" class="title">
					{{ $t(NO_CONNECTION_TITLE) }}
				</PrimeText>
				<PrimeText
					tag="div"
					size="sm"
					weight="500"
					color="grey-700"
					class="message"
				>
					{{ $t(NO_CONNECTION_MESSAGE) }}
				</PrimeText>
			</div>
			<PrimeButton
				type="gradient"
				full-width
				:label="$t(RELOAD)"
				@click="handleReloadPage"
			/>
		</div>
	</div>
</template>

<style lang="scss">
.no-connection {
	height: 100vh;
	width: 100%;
	position: absolute;
	z-index: 10000000;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--p-white);
}

.no-connection-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 21.25rem;
	text-align: center;

	& .button {
		width: 100%;
	}
}

.no-connection-content {
	margin: 1.5rem 0;

	& .message {
		margin: 1rem 0 2rem;
	}
}
</style>
