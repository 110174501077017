<script lang="ts" setup>
import { ref, watch } from 'vue';
import {
	BONUS_CODE,
	ENTER_CODE,
	SUBMIT,
	BONUS_CODE_MESSAGE,
	GOTCHA,
	CONGRATULATIONS,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import {
	PrimeInput,
	PrimeButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { redeemBonusCode } from '@/api';
import { getErrorResponse } from '@/utils/helpers';
import { PiConfettiDoutoneThin } from '@primeinsightsgroupllc/prime-icons';
import { usePrimeSurveysIntegration } from '@/utils/composables/useIntegrationScriptRender';
import { IntegrationModules } from '@/enums';

defineEmits<{
	(e: 'close-modal'): void;
}>();

const { renderPrimeSurveys } = usePrimeSurveysIntegration();

const code = ref('');
const errorMessage = ref('');
const loading = ref(false);
const codeSubmitted = ref(false);
const successSubmitMessage = ref('');
const isCodeInvalid = ref<'error' | null>(null);

const handleSubmit = async () => {
	try {
		loading.value = true;
		const { data, status } = await redeemBonusCode(code.value);
		if (status === 200) {
			renderPrimeSurveys({container: '#prime-survey', module: IntegrationModules.SURVEYS});
			codeSubmitted.value = true;
			successSubmitMessage.value = data.message;
		}
	} catch (e) {
		errorMessage.value = getErrorResponse(e).message;
		isCodeInvalid.value = 'error';
	} finally {
		loading.value = false;
	}
};

watch(code, (newValue) => {
	if (isCodeInvalid.value === 'error' && newValue) {
		errorMessage.value = '';
		isCodeInvalid.value = null;
	}
});
</script>

<template>
	<div
		class="bonus-code-modal add-padding"
		:class="{ 'add-padding': !codeSubmitted }"
	>
		<ModalCommonContent v-if="!codeSubmitted">
			<template #header>
				{{ $t(BONUS_CODE) }}
			</template>
			<template #message>{{ $t(BONUS_CODE_MESSAGE) }}</template>
			<template #inputs>
				<form class="bonus-code-form" @submit.prevent="handleSubmit">
					<PrimeInput
						v-model="code"
						:placeholder="$t(ENTER_CODE)"
						:invalid="!!isCodeInvalid"
						:error-text="errorMessage"
						:disabled="loading"
					/>
					<PrimeButton
						type="gradient"
						full-width
						role="submit"
						:label="$t(SUBMIT)"
						:disabled="!code"
						:loading="loading"
					/>
				</form>
			</template>
		</ModalCommonContent>
		<ModalCommonContent v-else>
			<template #header>
				{{ $t(BONUS_CODE) }}
			</template>
			<template #icon>
				<PiConfettiDoutoneThin size="100%" color="base-primary" />
			</template>
			<template #title>{{ $t(CONGRATULATIONS) }}</template>
			<template #message>{{ successSubmitMessage }}</template>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(GOTCHA)"
					@click="$emit('close-modal')"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.bonus-code-modal {
	.bonus-code-form {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 0.75rem;
		margin-top: 0.75rem;
	}
}

:deep(.p-modal-content) {
	.add-padding .modal-common-content.p-without-border-bottom & {
		padding-bottom: 1.375rem !important;
	}
}
</style>
